<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
    >
      <template #table-busy class="text-center">
        <b-spinner />
      </template>
      <template #cell(actions)="row">
        <b-button size="sm" variant="warning" @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>

    <b-modal
      id="modal-especialidades"
      size="sm"
      title="Cadastrar/Editar Especialidade"
      no-fade
      content-class="modalDireito"
      hide-footer
    >
      <especialidades-form
        ref="especialidadesFormComp"
        :onSaveDone="onSaveDone"
        :especialidade_edit="especialidade_edit"
      />
    </b-modal>
  </div>
</template>

<script>
// import EmpresasLib from '@/libs/EmpresasLib';
import EspecialidadesLib from "@/libs/EspecialidadesLib";
import EspecialidadesForm from "./EspecialidadesForm";

export default {
  components: {
    EspecialidadesForm,
  },
  props: {
    filtro: String,
  },
  data() {
    return {
      loading: false,
      lista: [],
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "especialidade", label: "Especialidade", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      especialidade_edit: {},
    };
  },
  mounted() {
    this.carregar();
  },
  methods: {
    async carregar() {
      try {
        this.loading = true;

        await this.$nextTick();
        this.lista = await EspecialidadesLib.getAPI();
      } catch (error) {
        console.log(`Error: ${error}`);
      } finally {
        this.loading = false;
      }
    },
    async novo() {
      this.especialidade_edit = "";
      this.$bvModal.show("modal-especialidades");
    },
    salvar() {
      this.$refs.especialidadesFormComp.salvar();
    },
    onSaveDone() {
      this.carregar();
      this.$bvModal.hide("modal-especialidades");
    },
    async excluir(especialidade) {
      if (especialidade) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir esta especialidade?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const res = await EspecialidadesLib.del({ id: especialidade.id });
              if (res && res.success) {
                this.$swal
                  .fire({
                    title: "Especialidade excluída com sucesso!",
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                  })
                  .then(() => {
                    this.carregar();
                  });
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir especialidade!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
    async editar(especialidade) {
      this.especialidade_edit = Object.assign({}, especialidade);
      this.$bvModal.show("modal-especialidades");
    },
    endEdit() {
      this.$bvModal.hide("modal-especialidades");
      this.especialidade_edit = null;
    },
  },
};
</script>

<style>
</style>