<template>
  <div>
        <BarraCrud :onFiltrar="filtrar" :botaoNovo="true" :onNovo="onNovo" />
        <especialidades-table :filtro="filtro" ref="especialidadesTableComp" />
     
  </div>
</template>

<script>
import BarraCrud from '../../components/common/BarraCrud.vue';
import EspecialidadesTable from '../../components/Especialiades/EspecialidadesTable'

export default {
    components:{
        BarraCrud,
        EspecialidadesTable,
        
    },
    props:{
        BarraCrud
    },
    data(){
        return{
            filtro: '',
            especialidades: []
        }
    },
    methods:{
        filtrar(filtro){
            this.filtro = filtro
        },
        onNovo(){
            this.$refs.especialidadesTableComp.novo()
        },
       
    }
}
</script>

<style>

</style>